import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const ScrollToAnchor = () => {
    const location = useLocation();

    // listen to location change using useEffect with location as dependency
    // https://jasonwatmore.com/react-router-v6-listen-to-location-route-change-without-history-listen
    useEffect(() => {
        const hash = location.hash.slice(1);
        if (hash.length && document.getElementById(hash)) {
            document
                .getElementById(hash)
                .scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    }, [location]);

    return null;
};
