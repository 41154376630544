import clsx from 'clsx';
import { ArrowLeftCircle } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { useScreenSize } from 'hooks/useScreenSize';

import styles from './WagonDetailsPageHeader.module.scss';

export const WagonDetailsPageHeader = ({ wagonCode }) => {
    const { isMobile } = useScreenSize();
    const navigate = useNavigate()

    const { t } = useTranslation();
    const { id } = useParams();

    const handleBack = () => {
        navigate(`/situations/${id}#${wagonCode}`);
    };

    return (
        <div className={styles.header}>
            <ArrowLeftCircle size={21} color="#888888" cursor="pointer" onClick={handleBack} />
            <span
                className={clsx(styles.title, {
                    [styles.sm]: isMobile
                })}
            >
                {`${t('carriage')} ${wagonCode}`}
            </span>
        </div>
    );
};
